import React from 'react';
import {IDrive} from 'spekit-types';
import {Box, Flex, Text} from 'spekit-ui';

interface IProps {
  drives: IDrive[];
  searchTerm: string;
}
export const SearchText = ({drives, searchTerm}: IProps) => {
  return (
    <Flex>
      <Text variant='body2' fontSize='small' fontWeight={400} letterSpacing='normal'>
        {`Displaying ${drives.length} search results for `}
        <Box as='span' fontWeight={600} fontSize='small' letterSpacing='normal'>
          "{searchTerm}"
        </Box>
      </Text>
    </Flex>
  );
};
