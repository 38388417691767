import React from 'react';
import {IFileOrFolder, IFilter} from 'spekit-types';
import {Box, Text} from 'spekit-ui';
import {FilterTag} from './FilterTag';
import {FolderBreadcrumbs} from './FolderBreadcrumbs';

interface IProps {
  hasSearched: boolean;
  isLoading: boolean;
  data: IFileOrFolder[];
  searchTerm: string;
  filters: IFilter;
  hasFiltered: boolean;
  breadcrumbs: {id: string; name: string}[];
  onBreadcrumbClick: (item: {id: string; name: string}) => void;
  onRemoveFilter: (tag: string) => void;
}

export const FilePickerInfo = (props: IProps) => {
  const {
    hasSearched,
    isLoading,
    data,
    searchTerm,
    filters,
    hasFiltered,
    breadcrumbs,
    onBreadcrumbClick,
    onRemoveFilter,
  } = props;
  if (hasSearched && !isLoading) {
    return (
      <Text
        data-testid='search-text'
        variant='body2'
        fontSize='small'
        fontWeight={400}
        letterSpacing='normal'
      >
        {`Displaying ${data.length} search results for `}
        <Box as='span' fontWeight={600} fontSize='small' letterSpacing='normal'>
          "{searchTerm}"
        </Box>
      </Text>
    );
  }

  if (hasFiltered) {
    return <FilterTag filters={filters} onRemoveFilter={onRemoveFilter} />;
  }

  return <FolderBreadcrumbs items={breadcrumbs} onItemClick={onBreadcrumbClick} />;
};
