import React from 'react';
import {createColumnHelper} from '@tanstack/react-table';
import {formatDateWithCustomMonthNames} from 'spekit-datalayer';
import {IFileOrFolder} from 'spekit-types';
import {Box, Checkbox, Flex, Text} from 'spekit-ui';
import {RecordIcon} from './RecordIcon';
import {isFolder} from '../../helpers';

export const columnHelper = createColumnHelper<IFileOrFolder>();

export const columns = [
  columnHelper.accessor('name', {
    header: ({table}) => (
      <>
        <Flex align='center' pl={16}>
          <Checkbox
            {...{
              isChecked: table.getIsAllRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              disabled: table.getRowCount() === 0,
            }}
          />
          <Text
            variant='body2'
            fontWeight={600}
            fontSize='small'
            textTransform='none'
            letterSpacing='normal'
            lineHeight={5}
          >
            Name
          </Text>
        </Flex>
      </>
    ),
    cell: ({row, getValue}) => (
      <Flex align='center' pl={16} tabIndex={-1}>
        {row.getCanSelect() ? (
          <Checkbox
            {...{
              isChecked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              onChange: row.getToggleSelectedHandler(),
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            pr={4}
            tabIndex={-1}
          />
        ) : (
          <Box w='25px' h='16px' pr={4} />
        )}
        <RecordIcon mimeType={row.original.fields.mimeType} />
        <Text
          pl={8}
          noOfLines={1}
          maxW='79%'
          variant='body2'
          fontSize='small'
          fontWeight={400}
          letterSpacing='normal'
          lineHeight={5}
          data-testid={`${getValue()}-${row.getCanSelect() ? 'file' : 'folder'}`}
        >
          <>{getValue()}</>
        </Text>
      </Flex>
    ),
  }),

  columnHelper.accessor('updatedTime', {
    header: () => (
      <Text
        variant='body2'
        fontWeight={600}
        fontSize='small'
        textTransform='none'
        letterSpacing='normal'
        lineHeight={5}
      >
        Last modified
      </Text>
    ),
    cell: ({getValue}) => (
      <Text
        variant='body2'
        fontSize='small'
        fontWeight={400}
        letterSpacing='normal'
        lineHeight={5}
      >
        {formatDateWithCustomMonthNames(getValue() as string)}
      </Text>
    ),
  }),
];
