import React, {useCallback, useEffect, useState} from 'react';
import {flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import Lottie from 'lottie-react';
import {IFileOrFolder} from 'spekit-types';
import {Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from 'spekit-ui';
import {TIntegrationKeys} from '../../constants';
import {isFolder} from '../../helpers';
import {columns} from './Columns';
import loadingAnimation from './loadingAnimation.json';

interface ITableProps {
  isLoading: boolean;
  data: IFileOrFolder[];
  store: TIntegrationKeys;
  handleFolderClick: (row: IFileOrFolder) => void;
  onFilesSelectionChange: (files: IFileOrFolder[]) => void;
}

export const FilePickerTable = (props: ITableProps) => {
  const {isLoading, data, store, handleFolderClick, onFilesSelectionChange} = props;

  const [rowSelection, setRowSelection] = useState({});
  const [previousSelectedFiles, setPreviousSelectedFiles] = useState<IFileOrFolder[]>([]);

  const handleRowSelection = useCallback(() => {
    const selectedIds = Object.keys(rowSelection);

    const dataMap = new Map(data.map((file) => [file.id, file]));
    const previousMap = new Map(previousSelectedFiles.map((file) => [file.id, file]));

    const allSelectedFiles = selectedIds.map((id) => {
      const currentFile = dataMap.get(id);
      if (currentFile) return currentFile;

      return previousMap.get(id);
    });

    setPreviousSelectedFiles(allSelectedFiles as IFileOrFolder[]);

    onFilesSelectionChange(allSelectedFiles as IFileOrFolder[]);
  }, [rowSelection]);

  useEffect(() => {
    handleRowSelection();
  }, [handleRowSelection]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => !isFolder(store, row.original.fields), // Allow selection of files only
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
  });

  return (
    <TableContainer
      border='1px solid'
      borderColor='neutral.100'
      borderRadius={6}
      height='500px'
      overflowY='auto'
      whiteSpace='normal'
    >
      <Table size='sm'>
        <Thead bg='neutral.25' position='sticky' top={0} zIndex={1}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  py={12}
                  px={0}
                  _first={{width: '80%'}}
                  borderColor='neutral.100'
                  position='sticky'
                  left={0}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody>
          {!isLoading &&
            data.length > 0 &&
            table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                _hover={{bg: 'neutral.50'}}
                tabIndex={0}
                _focusVisible={{
                  outlineColor: 'primary.500',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (row.getCanSelect()) {
                      row.getToggleSelectedHandler()(e);
                    } else {
                      handleFolderClick(row.original);
                    }
                  }
                }}
                cursor='pointer'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (row.getCanSelect()) {
                    row.getToggleSelectedHandler()(e);
                  } else {
                    handleFolderClick(row.original);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} py={10} px={0} borderColor='neutral.100'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}

          {!isLoading && data.length === 0 && (
            <Tr>
              <Td border='none' colSpan={2} textAlign='center' height='450px'>
                <Text variant='body1' fontWeight={400}>
                  No contents
                </Text>
              </Td>
            </Tr>
          )}
          {isLoading && (
            <Tr>
              <Td border='none' colSpan={2} textAlign='center' height='450px'>
                <Lottie
                  animationData={loadingAnimation}
                  loop={true}
                  style={{height: '250px'}}
                />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
