import React from 'react';
import {Flex, Text, DSButton as Button} from 'spekit-ui';

interface IProps {
  handleSearchClear: () => void;
}

export const NoSearchResults = (props: IProps) => {
  const {handleSearchClear} = props;

  return (
    <Flex
      mt={24}
      mb={64}
      direction='column'
      align='center'
      justify='center'
      textAlign='center'
    >
      <Text variant='body1' fontWeight={600} mb={12}>
        No results found
      </Text>
      <Text variant='body2' fontWeight={400}>
        The term you entered didn't bring up any results. Modify <br /> your search term
        and try again.
      </Text>
      <Button variant='outlined' size='medium' mt={24} onClick={handleSearchClear}>
        Clear search
      </Button>
    </Flex>
  );
};
