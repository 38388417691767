import React, {useState} from 'react';
import type {IDrive, IFileOrFolder, IFilePickerParams, IFilter} from 'spekit-types';
import {Box, Flex, SearchBar} from 'spekit-ui';
import {TIntegrationKeys, typeFilterOptions, periodFilterOptions} from '../../constants';
import {getParentId} from '../../helpers';
import {FilePickerTable} from './FilePickerTable';
import {useListFiles} from '../../hooks/useListFiles';
import {useSharePointSearch} from '../../hooks/useSharePointSearch';
import {FilterPopover} from './FilterPopover';
import {FilePickerInfo} from './FilePickerInfo';

interface IProps {
  store: TIntegrationKeys;
  drive?: IDrive;
  onFilesSelectionChange: (files: IFileOrFolder[]) => void;
}

interface IBreadcrumb {
  id: string;
  name: string;
}

export const FilePicker = (props: IProps) => {
  const {store, drive, onFilesSelectionChange} = props;
  const initialBreadcrumbs = drive ? [{id: drive.id, name: drive.name}] : [];

  const [searchTerm, setSearchTerm] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [oldSearchTerm, setOldSearchTerm] = useState('');
  const [filters, setFilters] = useState<IFilter>({
    type: typeFilterOptions[0],
    period: periodFilterOptions[0],
  });

  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(initialBreadcrumbs);
  const [queryParams, setQueryParams] = useState<IFilePickerParams>({
    store,
    drive,
    parentId: drive?.id ?? '',
    searchTerm,
    filter: filters,
  });

  const {data: sharePointSearchData, isLoading: sharePointSearchIsLoading} =
    useSharePointSearch({...queryParams, parentId: ''});
  const {data: listFilesData, isLoading: listFilesIsLoading} = useListFiles(queryParams);

  const data = listFilesData ?? sharePointSearchData ?? [];
  const isLoading = listFilesIsLoading && sharePointSearchIsLoading;

  const getFolder = async (parentId: string | null) => {
    setQueryParams({...queryParams, parentId});
  };

  const handleFolderClick = async (row: IFileOrFolder) => {
    const parentId = getParentId(row.fields.id, drive?.id);
    setBreadcrumbs([...breadcrumbs, {id: parentId ?? 'root', name: row.fields.name}]);

    getFolder(parentId);
  };

  const handleBreadcrumbClick = async (item: {id: string; name: string}) => {
    const selectedBreadcrumbIndex = breadcrumbs.findIndex(
      (crumb) => crumb.id === item.id
    );
    setBreadcrumbs(breadcrumbs.slice(0, selectedBreadcrumbIndex + 1));
    getFolder(item.id);
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    setQueryParams({...queryParams, searchTerm: '', parentId: drive?.id ?? ''});
    setHasSearched(false);
    setBreadcrumbs(initialBreadcrumbs);
  };

  const handleSearch = () => {
    setQueryParams({...queryParams, searchTerm});
    setHasSearched(Boolean(searchTerm));
    setOldSearchTerm(searchTerm || '');
    setBreadcrumbs(initialBreadcrumbs);
  };

  const handleFilterChange = (filter: IFilter) => {
    setFilters(filter);
  };

  const handleApplyFilters = async () => {
    setQueryParams({...queryParams, filter: filters});
  };

  const handleResetFilters = () => {
    setFilters({
      type: typeFilterOptions[0],
      period: periodFilterOptions[0],
    });
    setQueryParams({
      ...queryParams,
      filter: {
        type: typeFilterOptions[0],
        period: periodFilterOptions[0],
      },
    });
  };

  const handleRemoveFilter = (key: string) => {
    const updatedFilters = {...filters};
    if (key === 'type') {
      updatedFilters.type = typeFilterOptions[0];
    } else if (key === 'period') {
      updatedFilters.period = periodFilterOptions[0];
    }
    setFilters(updatedFilters);
    setQueryParams({
      ...queryParams,
      filter: updatedFilters,
    });
  };

  return (
    <Box my={24} overflowY='auto'>
      <Flex align='center' justify='space-between' mb={16} pt={4}>
        <FilePickerInfo
          hasSearched={hasSearched}
          isLoading={isLoading}
          data={data}
          searchTerm={oldSearchTerm}
          filters={filters}
          hasFiltered={Boolean(
            filters.type.value !== 'all' || filters.period.value !== 'any'
          )}
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={handleBreadcrumbClick}
          onRemoveFilter={handleRemoveFilter}
        />

        <Flex ml='auto' gap={8}>
          <SearchBar
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onSearch={handleSearch}
            onClear={handleSearchClear}
            isDisabled={isLoading}
          />
          {store === 'gdrive' && (
            <FilterPopover
              filters={filters}
              onChange={handleFilterChange}
              onFilter={handleApplyFilters}
              onReset={handleResetFilters}
            />
          )}
        </Flex>
      </Flex>

      <FilePickerTable
        isLoading={isLoading}
        data={data}
        store={store}
        handleFolderClick={handleFolderClick}
        onFilesSelectionChange={onFilesSelectionChange}
      />
    </Box>
  );
};
